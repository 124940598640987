// src/globalStyles.ts
import { globalCss } from "@stitches/react";
var globalStyles = globalCss({
  "*": { margin: 0, padding: 0 }
});

// src/styled.ts
import themeConfig from "@myturborepo/theme";
import { createStitches } from "@stitches/react";
var { styled, css, config, theme, getCssText } = createStitches({
  theme: themeConfig
});

// src/lib/Button/Button.tsx
var Button = styled("button", {
  backgroundColor: theme.colors.green500,
  border: "0",
  borderRadius: "9999px",
  color: "white",
  cursor: "pointer",
  fontSize: "13px",
  padding: `${theme.space[2]} ${theme.space[3]}`,
  "&:hover": {
    backgroundColor: theme.colors.purple500
  }
});

// src/lib/Box.tsx
var Box = styled("div", {});
export {
  Box,
  Button,
  config,
  css,
  getCssText,
  globalStyles,
  styled,
  theme
};
